import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUser } from 'src/utils/useUser'
import getHomeRoute from 'src/layouts/components/acl/getHomeRoute'

const Home = () => {
  const auth = useUser()
  const router = useRouter()
  const [userDetailsLoaded, setUserDetailsLoaded] = useState(false)

  useEffect(() => {
    if (!auth.isLoading && auth.user && auth.userDetails) {
      setUserDetailsLoaded(true)
    }
  }, [auth.isLoading, auth.user, auth.userDetails])

  useEffect(() => {
    if (userDetailsLoaded) {
      if (auth.user && auth.userDetails && auth.userDetails.role) {
        const homeRoute = getHomeRoute(auth.userDetails.role)
        router.replace(homeRoute)
      } else {
        router.replace('/login')
      }
    }
  }, [userDetailsLoaded, auth.user, auth.userDetails, router])

  return null
}

export default Home
